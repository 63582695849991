import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isStartClass:0, //状态0未开始1进行中2已结束3取消
    boardFileId:'',
    classInfo:{},
    streamId:'',
    WhiteboardId:'',
    taskId:''
  },
  getters: {
  },
  mutations: {
    changeIsStartClass(state,payload){
      state.isStartClass = payload
    },
    changeBoardFileId(state,payload){
      state.boardFileId = payload
    },
    changeClassInfo(state,payload){
      state.classInfo = payload
    },
    changeStreamId(state,payload){
      state.streamId = payload
    },
    changeWhiteboardId(state,payload){
      state.WhiteboardId = payload
    },
    changeTalkId(state,payload){
      state.taskId = payload
    }

  },
  actions: {
  },
  modules: {
  },
});
