import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/styles/common.css'
import {i18n} from './i18n/index.js';
import { message } from './utils/resetMessage';
import{ debounce, formatDate, getLabelByValue } from '@/utils/common.js'
import { filters } from '@/utils/filters.js'
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
Vue.use(ElementUI);
Vue.prototype.$debounce = debounce
Vue.prototype.$formatDate = formatDate
Vue.prototype.$getLabelByValue = getLabelByValue
Vue.prototype.$message = message
// 错误捕获
Vue.config.errorHandler = function (err, vm, info) {
  console.error('全局捕获 err >>>', err)
  console.error('全局捕获 vm >>>', vm)
  console.error('全局捕获 info >>>', info)
}

// 定义全局自定义过滤器
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
