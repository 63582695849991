const zh = {
  login: {
    title: '欢迎来到伙伴',
    accountLogin: '手机密码登录',
    codeLogin: '验证码登录',
    login: '立即登录',
    noAccount: '还没有账号?',
    gotoRegister: '去注册',
    forgetPassword: '忘记密码',
    register: '立即注册',
    getCode: '获取验证码',
    pleaseEnter: '请输入',
    pleaseAgree: '我已阅读并同意',
    userAgreement: '用户使用协议',
    privacyPolicy: '隐私政策',
    enterAccount: '手机号码',
    enterPassword: '请输入密码',
    enterCode: '请输入验证码',
    correctCode: '请输入正确的验证码',
    codeSendSuccess: '验证码发送成功',
    correctAccount: '请输入正确的账号',
    passwordNotNull: '密码不能为空',
    accountNotNull: '手机号码不能为空',
    noAgree: '请先阅读并同意用户使用协议和隐私政策',
    registerTitle: '用户注册',
    againPassword: '再次输入密码',
    passwordNotSame: '两次密码输入不一致',
    changePassword: '修改密码',
    and: '和',
    accountError: '邮箱账号/手机号格式不正确!',
    registerSuccess: '注册成功',
  },
  teacher: {
    teacherPlan: '直播计划',
    courseLibrary: '课件库',
    course: '应约课程',
    noCourse: '待应约课程',
    courseName: '请输入课程名称',
    smallClass: '小班课',
    vs: '对',
    enterClass: '进入教室',
    confirm: '应约',
  },
  student: {
    studyPlan: '学习计划',
    buyCourse: '购买课时',
    myOrder: '我的订单',
    studyReport: '学习报告',
    cancel: '取消预约',
    oneLangPractice: '外语一对一陪练',
    promotion: '晋级为代理商',
    experience: '体验课',
    buy: '立即购买',
    productDetail: '商品详情',
    productName: '商品名称',
    subject: '学科',
    practiceWay: '陪练方式',
    practiceTime: '课堂时长',
    courseType: '课堂类型',
    quantity: '库存',
    remark: '商品简介',
    course: '课程',
    partnerTraining: '陪练',
    number: '数量',
    actualPayment: '实付款',
    orderStatus: '交易状态',
    remainingTime: '剩余课时',
    operating: '操作',
    orderTime: '下单时间',
    orderNumber: '订单编号',
    paymentDeadline: '付款截止',
    remaining: '剩余',
    duration: '时长',
    foreignLanguage: '外语',
    onlinePayment: '在线支付',
    immediatePayment: '立即付款',
    cancelOrder: '取消订单',
    allOrder: '全部订单',
    waitingPayment: '待付款',
    paid: '已支付',
    expired: '已取消/过期',
    refunded: '已退款',
    appointmentClass: '预约课程',
    appointmentBtn: '立即预约',
    selectChapter: '选择章节',
    confirmTeacher: '预约老师',
    selectTime: '预约陪练时间',
    confirmAppointment: '确定预约',
    fullClass: '正课',
    trialClass: '体验课',
    district:'地区',
    specialtCourse:'擅长课程',
    compositeScore:'综合得分',
    interactiveTextbook:'互动教材',
    notAvailable:'不可预约',
    learningReport:'学习报告',
    messageNotification:'消息通知',
    selectChapter:'选择章节',
    PleaseSelectChapter:'请选择章节',
    classroomStyle:'课堂方式',
    PleaseclassroomStyle:'请选择课堂方式',
    sparringStyle:'陪练方式',
    PleasesparringStyle:'请选择陪练方式',
    classTime:'课堂时间',
    PleaseclassTime:'请选择课堂时间',
    sparringClass:'陪练课程',
    title:'头衔',
    jobStatus:'在职情况',
    comprehensiveScore:'综合评分',
    studentRating:'学生评分',
    AcademicAffairsSection:'教务组巡察',
    AppointmentSchedule: '预约时间表',
    setTime:'设置休息时间',
    setUpBreaks:'设置休息时段',
    novice:'初级',
    intermediate:'中级',
    advanced:'高级',
    beOnTheJob:'在职',
    dimission:'离职'
  },
  other: {
    cancelSuccess: '取消成功',
    today: '今天',
    year: '年',
    month: '月',
    day: '日',
    hour: '时',
    minute: '分',
    second: '秒',
    sun: '日',
    mon: '一',
    tue: '二',
    wed: '三',
    thu: '四',
    fri: '五',
    sat: '六',
    week: '星期',
    confirmCancel: '确认取消？',
    tip: '提示',
    confirm: '确定',
    cancel: '取消',
    courseTime: '课时',
    alipay: '支付宝支付',
    remaining: '剩余支付时间',
    personalFile: '个人档案',
    teacherFile: '教学档案',
    studyFile: '学习档案',
    selfIntroduction: '自我介绍',
    logout: '退出登录',
    personalCenter: '个人中心',
    officialCourse: '官方课件',
    pleaseSelect: '请选择',
    pleaseConfirm: '请确认转文本功能已打开,并点击需要翻译的文本',
    en: '英文',
    cn: '中文',
    translate: '转文字',
    startTranslate: '开始翻译',
  },
  center: {
    basicInfo: '基础信息',
    organization: '所属机构',
    gender: '性别',
    male: '男性',
    female: '女性',
    phone: '手机号',
    changePhone: '更换号码',
    country: '国家',
    grade: '就读年级',
    account: '账号',
    birthday: '生日',
    email: '邮箱',
    city: '城市',
    school: '就读学校',
    fullName: '全名',
    abbreviation: '简称',
    age: '年龄',
    years: '岁',
    identity: '身份证/护照',
    address: '居住地',
    educationInfo: '教育信息',
    otherInfo: '其他信息',
    uploadAvatar: '上传头像',
    upload: '上传',
    otherCertificate: '其他相关证书',
    motherJob: '母亲职业',
    mathScore: '数学成绩',
    highestDegree: '最高学历',
    chineseLevel: '中文熟练度',
    classRank: '班级排名',
    remedial: '补习情况',
    englishScore: '英语成绩',
    graduateSchool: '毕业院校',
    englishCertificate: '英语证书',
    gradeRank: '年级排名',
    fatherJob: '父亲职业',
    chineseScore: '语文成绩',
    submit: '保存',
    langLevel: '外语评级',
    practiceTime: '陪练时长',
    courseType: '陪练',
    minute: '分钟',
    remainingTime: '剩余课时',
    newPhone: '新手机号码',
    associateDegree: '大专',
    bachelorDegree: '本科',
    MasterDegree: '硕士',
    DoctorateDegree: '博士',
    noKnow: '不懂',
    know: '懂一点',
    understand: '听得懂',
    fluent: '能交流',
    notRequired: '(非必填)',
    format: '上传头像图片只能是 JPG/PNG/JPEG 格式!',
    size: '上传头像图片大小不能超过 5MB!',
    saveSuccess: '保存成功',
    correctPhoneOrEmail: '请输入正确的手机号或邮箱',
    correctEmail: '请输入正确的邮箱',
    samePhone: '新手机号码不能与旧手机号码相同',
    phoneNotNull: '手机号码不能为空',
    codeNotNull: '验证码不能为空',
    modifySuccess: '修改成功',
    correctPhone: '请输入正确的手机号码',
    name: '姓名',
    pleaseUpload: '请上传',
  },
  room: {
    forcedEnd: '该房间已被管理员强制结束',
    classesBegin: '开始上课',
    endClass: '结束课程',
    startRecording: '开始录制',
    endRecording: '结束录制',
    whiteboard: '进入白板',
    selectCourseware: '选择课件',
    empoweringStudentTools: '授权学生工具',
    empoweringTools: '授权工具',
    praiseEffect: '表扬特效',
    comment: '点评',
    leaveClass: '离开课堂',
    confirmEndCourse: '确认结束课程?',
    tip: '提示',
    confirm: '确定',
    cancel: '取消',
    classOver: '课堂已结束',
    notStartClass: '未开始上课',
    recordOrNot: '是否开启录制',
    endRecordOrNot: '是否结束录制',
    confirmLeaveClass: '确定离开当前课堂?',
    notScreen: '无法捕捉屏幕',
    move: '拖拽',
    pen: '画笔',
    eraser: '橡皮擦',
    laserPen: '激光笔',
    empty: '清空',
    selectStudent: '选择学生',
    selectTeacher: '选择老师',
    pleaseSelect: '请选择',
    stuLanguageLevel: '学生外语等级',
    stuRemark: '学生评语',
    teaRemark: '老师评语',
    enterRemark: '请输入评语',
    pleaseSelectStudent: '请选择学生',
    pleaseSelectTeacher: '请选择老师',
    pleaseSelectLevel: '请选择外语水平',
    commentSuccess: '点评成功',
    recording: '录制中',
    deviceCheck: '设备检测',
    camera: '摄像头',
    preview: '预览',
    checkAccreditType: '查看授权方式',
    questionCheck: '问题排查',
    cameraText1: '在浏览器“允许”使用摄像头',
    cameraText2: '在系统“允许”使用摄像头',
    cameraText3: '请确认摄像头已正确连接并开启',
    cameraText4: '请确认摄像头没有被其他软件占用',
    islookFrame: '你是否可以看到摄像头画面?',
    mic: '麦克风',
    micText1: '在浏览器“允许”使用麦克风',
    micText2: '在系统“允许”使用麦克风',
    micText3: '请确认麦克风已正确连接并开启(红色孔)',
    micText4: '请确认麦克风没有被其他软件占用',
    oneToFive: '从1数到5，您是否可以看到音量条的波动？',
    speaker: '扬声器',
    speakerText1: '请确认扬声器已正确连接并开启(绿色孔)',
    speakerText2: '请确认扬声器没有被其他软件占用',
    speakerText3: '请调节扬声器的声音，确保可以听清楚',
    isListen: '你是否可以听到扬声器发出的声音？',
    checkResult: '检测结果',
    check: '检测',
    noListen: '无法听到',
    noLook: '无法看到',
    canListen: '可以听到',
    canLook: '可以看到',
    againCheck: '重新检测',
    loginRoom: '加入直播间',
    cameraAccreditType1: '检查浏览器是否”允许”使用摄像头（Chrome）',
    cameraAccreditType2: '隐私设置和安全性-网站设置-摄像头-设置允许',
    cameraAccreditType3: '检查系统是否”允许”使用摄像头',
    cameraAccreditType4: 'Windows设置-隐私-应用权限-相机',
    cameraAccreditType5: '系统偏好设置-安全性与隐私-摄像头-勾选浏览器',
    micAccreditType1: '检查浏览器是否”允许”使用麦克风（Chrome）',
    micAccreditType2: '隐私设置和安全性-网站设置-麦克风-设置允许',
    micAccreditType3: '检查系统是否”允许”使用麦克风',
    micAccreditType4: 'Windows设置-隐私-应用权限-麦克风',
    micAccreditType5: '系统偏好设置-安全性与隐私-麦克风-勾选浏览器',
    deviceNoCheck: '设备检测未完成，将可能影响课堂效果',
    isGiveUpCheck: '是否放弃检测?',
    continueCheck: '继续检测',
    giveUpCheck: '放弃检测',
    cameraAccreditType: '摄像头授权方式',
    micAccreditType: '麦克风授权方式',
    penSize: '笔触粗细',
    penColor: '笔触颜色',
    currentBoard: '已是当前白板',
    classIsEnd: '课程已结束',
  }
}
export default zh;
