const dayjs = require('dayjs')
// 防抖
export function debounce(func, wait) {
  let timeout;
  return function() {
    const context = this, args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(context, args);
    }, wait);
  };
}

// 通过dayjs格式化日期
// *
// * @param date 日期
// * @param fmt 格式,如:YYYY-MM-DD HH:mm:ss
// * @returns
export function formatDate(date, fmt) {
  return dayjs(date).format(fmt)
}

/**
 * 根据value获取label
 * @param {*} list
 * @param {*} value
 */
export function getLabelByValue(list, value) {
  let label = ''
  list.forEach(item => {
    if (item.dictValue == value) {
      label = item.dictLabel
    }
  })
  return label
}
