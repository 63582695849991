const en = {
  login: {
    title: 'Welcome to foreign partner',
    accountLogin: 'Account password login',
    codeLogin: 'Verification code login',
    login: 'Login',
    noAccount: 'Don\'t have an account?',
    gotoRegister: 'Register',
    forgetPassword: 'Forget password',
    register: 'Register', 
    getCode: 'Get code',
    pleaseEnter: 'Please enter',
    pleaseAgree: 'I have read and agree',
    userAgreement: 'User use agreement',
    privacyPolicy: 'Privacy policy',
    enterAccount: 'Email account/phone number',
    enterPassword: 'Please enter password',
    enterCode: 'Please enter verification code',
    correctCode: 'Please enter correct verification code',
    codeSendSuccess: 'Verification code sent successfully',
    correctAccount: 'Please enter correct account',
    passwordNotNull: 'Password cannot be empty',
    accountNotNull: 'Email account/phone number cannot be empty',
    noAgree: 'Please read and agree to the user use agreement and privacy policy',
    registerTitle: 'User registration',
    againPassword: 'Enter again password',
    passwordNotSame: 'The two passwords entered are inconsistent',
    changePassword: 'Change password',
    and: 'and',
    accountError: 'Email account/phone number format is incorrect!',
    registerSuccess: 'Registration successful',
  },
  teacher: {
    teacherPlan: 'Teacher plan',
    courseLibrary: 'Course library',
    course: 'Course',
    noCourse: 'No course',
    courseName: 'Course name',
    smallClass: 'Small class',
    vs: 'vs',
    enterClass: 'Enter',
    confirm: 'Confirm',
  },
  student: {
    studyPlan: 'Study plan',
    buyCourse: 'Buy course',
    myOrder: 'My order',
    studyReport: 'Study report',
    cancel: 'Cancel',
    oneLangPractice: 'One-to-one practice',
    promotion: 'Promotion',
    experience: 'Experience',
    buy: 'Buy',
    productDetail: 'Product details',
    productName: 'Product name',
    subject: 'Subject',
    practiceWay: 'Practice way',
    practiceTime: 'Practice time',
    courseType: 'Course type',
    quantity: 'Quantity',
    remark: 'Product description',
    course: 'Course',
    partnerTraining: 'Partner training',
    number: 'Number',
    actualPayment: 'Actual payment',
    orderStatus: 'Order status',
    remainingTime: 'Remaining time',
    operating: 'Operation',
    orderTime: 'Order time',
    orderNumber: 'Order number',
    paymentDeadline: 'Payment deadline',
    remaining: 'Remaining',
    duration: 'Duration',
    foreignLanguage: 'Foreign language',
    onlinePayment: 'Online payment',
    immediatePayment: 'Immediate payment',
    cancelOrder: 'Cancel order',
    allOrder: 'All order',
    waitingPayment: 'Waiting payment',
    paid: 'Paid',
    expired: 'Cancel/Expired',
    refunded: 'Refunded',
    appointmentClass: 'Book a course',
    appointmentBtn: 'Book now',
    selectChapter: 'Select chapter',
    confirmTeacher: 'Book a teacher',
    selectTime: 'Make an appointment for sparring',
    confirmAppointment: 'Confirm appointment',
    fullClass: 'Full class',
    trialClass: 'Trial class',
    district: 'district',
    specialtCourse: 'Specialty course',
    compositeScore: 'Composite Score',
    interactiveTextbook: 'Textbook',
    notAvailable: 'Not available',
    learningReport: 'Learning report',
    messageNotification: 'Message notification',
    selectChapter: 'Select chapter',
    PleaseSelectChapter: 'Please Select Chapter',
    classroomStyle: 'Classroom style',
    PleaseclassroomStyle: 'Please Classroom style',
    sparringStyle: 'Sparring style',
    PleasesparringStyle: 'Please Sparring style',
    classTime: 'Class time',
    PleaseclassTime: 'Please Class time',
    sparringClass: 'Sparring Class',
    title: 'Title',
    jobStatus: 'On-the-job status',
    comprehensiveScore: 'Comprehensive score',
    studentRating: 'Student rating',
    AcademicAffairsSection: 'Academic affairs section',
    AppointmentSchedule: 'Appointment schedule',
    setTime:'Set a break time',
    setUpBreaks:'Set up breaks',
    novice:'novice',
    intermediate:'intermediate',
    advanced:'advanced',
    beOnTheJob:'Be on the job',
    dimission:'dimission'
  },
  other: {
    cancelSuccess: 'Cancel success',
    today: 'Today',
    year: 'Year',
    month: 'Month',
    day: 'Day',
    hour: 'Hour',
    minute: 'Minute',
    second: 'Second',
    sun: 'Sun',
    mon: 'Mon',
    tue: 'Tue',
    wed: 'Wed',
    thu: 'Thu',
    fri: 'Fri',
    sat: 'Sat',
    week: 'Week',
    confirmCancel: 'Confirm cancel?',
    tip: 'Tip',
    confirm: 'Confirm',
    cancel: 'Cancel',
    courseTime: 'Course time',
    alipay: 'Alipay',
    remaining: 'Remaining payment time',
    personalFile: 'Personal file',
    teacherFile: 'Teacher file',
    studyFile: 'Study file',
    selfIntroduction: 'Self introduction',
    logout: 'Logout',
    personalCenter: 'Per center',
    officialCourse: 'Official course',
    pleaseSelect: 'Please select',
    pleaseConfirm: 'Please confirm that the function of text conversion is turned on, and click the text to be translated.',
    en: 'en',
    cn: 'cn',
    translate: 'Translate',
    startTranslate: 'Start trans',
  },
  center: {
    basicInfo: 'Basic information',
    organization: 'Organization',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    phone: 'Phone',
    changePhone: 'Change phone',
    country: 'Country',
    grade: 'Grade',
    account: 'Account',
    birthday: 'Birthday',
    email: 'Email',
    city: 'City',
    school: 'School',
    fullName: 'Full name',
    abbreviation: 'Abbreviation',
    age: 'Age',
    years: 'Years',
    identity: 'Identity card/passport',
    address: 'Address',
    educationInfo: 'Education information',
    otherInfo: 'Other information',
    uploadAvatar: 'Upload avatar',
    upload: 'Upload',
    otherCertificate: 'Other related certificates',
    motherJob: 'Mother\'s job',
    mathScore: 'Math score',
    highestDegree: 'Highest degree',
    chineseLevel: 'Chinese level',
    classRank: 'Class rank',
    remedial: 'Remedial',
    englishScore: 'English score',
    graduateSchool: 'Graduate school',
    englishCertificate: 'English certificate',
    gradeRank: 'Grade rank',
    fatherJob: 'Father\'s job',
    chineseScore: 'Chinese score',
    submit: 'Submit',
    langLevel: 'Language level',
    practiceTime: 'Practice time',
    courseType: 'Course type',
    minute: 'Minute',
    remainingTime: 'Remaining time',
    newPhone: 'New phone',
    associateDegree: 'Associate degree',
    bachelorDegree: 'Bachelor\'s degree',
    MasterDegree: 'Master\'s degree',
    DoctorateDegree: 'Doctorate degree',
    noKnow: 'I don\'t know',
    know: 'I know',
    understand: 'I understand',
    fluent: 'Can communicate',
    notRequired: '(Not required)',
    format: 'Upload avatar picture only can be JPG/PNG/JPEG format!',
    size: 'Upload avatar picture size cannot exceed 5MB!',
    saveSuccess: 'Save success',
    correctPhoneOrEmail: 'Please enter the correct phone number or email',
    correctEmail: 'Please enter the correct email',
    samePhone: 'The new phone number cannot be the same as the old phone number',
    phoneNotNull: 'Phone number cannot be empty',
    codeNotNull: 'Verification code cannot be empty',
    modifySuccess: 'Modify success',
    correctPhone: 'Please enter the correct phone number',
    name: 'Name',
    pleaseUpload: 'Please upload',
  },
  room: {
    forcedEnd: 'The room has been forcibly closed by the administrator',
    classesBegin: 'Class begin',
    endClass: 'End class',
    startRecording: 'Start recording',
    endRecording: 'End recording',
    whiteboard: 'Whiteboard',
    selectCourseware: 'Select courseware',
    empoweringStudentTools: 'Empowering student tools',
    empoweringTools: 'Empowering tools',
    praiseEffect: 'Praise effect',
    comment: 'comment',
    leaveClass: 'Leave class',
    confirmEndCourse: 'Confirm the end of the course?',
    tip: 'Tip',
    confirm: 'Confirm',
    cancel: 'Cancel',
    classOver: 'Class is over',
    notStartClass: 'Not start class',
    recordOrNot: 'Whether to enable recording',
    endRecordOrNot: 'Whether to end recording',
    confirmLeaveClass: 'Are you sure you want to leave the current class?',
    notScreen: 'Unable to capture screen',
    move: 'Move',
    pen: 'Pen',
    eraser: 'Eraser',
    laserPen: 'aserPen',
    empty: 'Empty',
    selectStudent: 'Select the student',
    selectTeacher: 'Choose a teacher',
    pleaseSelect: 'Please select',
    stuLanguageLevel: 'Students foreign language level',
    stuRemark: 'Student Comments',
    teaRemark: 'Teachers comments',
    enterRemark: 'Please enter a comment',
    pleaseSelectStudent: 'Please select a student',
    pleaseSelectTeacher: 'Please select a teacher',
    pleaseSelectLevel: 'Please select a foreign language level',
    commentSuccess: 'The review was successful',
    recording: 'Recording',
    deviceCheck: 'Device detection',
    camera: 'Camera',
    preview: 'Preview',
    checkAccreditType: 'View the authorization method',
    questionCheck: 'Troubleshooting',
    cameraText1: '"Allow" the use of the camera in your browser',
    cameraText2: 'The camera is "allowed" in the system',
    cameraText3: 'Please confirm that the camera is properly connected and turned on',
    cameraText4: 'Make sure that the camera is not occupied by other software',
    islookFrame: 'Whether you can see the camera feed?',
    mic: 'Microphone',
    micText1: '"Allow" the use of the microphone in the browser',
    micText2: 'The microphone is "allowed" in the system',
    micText3: 'Please make sure the microphone is properly connected and turned on (red hole)',
    micText4: 'Please make sure that the microphone is not occupied by other software',
    oneToFive: 'Counting from 1 to 5, can you see the fluctuation of the volume bar?',
    speaker: 'Loudspeaker',
    speakerText1: 'Please confirm that the speaker is properly connected and turned on (green hole)',
    speakerText2: 'Please confirm that the speaker is not occupied by other software',
    speakerText3: 'Please adjust the sound of the speaker to make sure you can hear it clearly',
    isListen: 'Can you hear the sound coming from the speakers?',
    checkResult: 'Test results',
    check: 'Check',
    noListen: "Can't be heard",
    noLook: "Can't see it",
    canListen: 'Can be heard',
    canLook: 'Can see it',
    againCheck: 'Retest',
    loginRoom: 'Join the live broadcast room',
    cameraAccreditType1: 'Check if your browser "allows" the use of the camera (Chrome)',
    cameraAccreditType2: 'Privacy Settings & Security - Website Settings - Camera - Settings Allowed',
    cameraAccreditType3: 'Check if the system "allows" the use of the camera',
    cameraAccreditType4: 'Windows Settings - Privacy - App Permissions - Camera',
    cameraAccreditType5: 'System Preferences - Security & Privacy - Camera - Tick Browser',
    micAccreditType1: 'Check if the browser "allows" the use of the microphone (Chrome)',
    micAccreditType2: 'Privacy & Security - Website Settings - Microphone - Settings Allowed',
    micAccreditType3: 'Check if the system "allows" the use of the microphone',
    micAccreditType4: 'Windows Settings - Privacy - App Permissions - Microphone',
    micAccreditType5: 'System Preferences - Security & Privacy - Microphone - Tick Browser',
    deviceNoCheck: 'If the device detection is not completed, it may affect the effectiveness of the class',
    isGiveUpCheck: 'Whether to abandon the test?',
    continueCheck: 'Continue testing',
    giveUpCheck: 'Abandon detection',
    cameraAccreditType: 'Camera authorization method',
    micAccreditType: 'Microphone authorization method',
    penSize: 'Stroke thickness',
    penColor: 'Stroke color',
    currentBoard: 'It is already the current whiteboard',
    classIsEnd: 'The course has ended',

  }
}
export default en;
